<template>
  <div class="flex flex-wrap">
    <component
      :is="facetTypeComponentMapper[facet.type || '']"
      v-for="(facet, idx) in selectedFacets"
      :key="idx"
      :label="facet.label"
      :code="facet.code"
      :value="facet.value"
      @click="handleOnSelectItem(facet)"
    >
    </component>
  </div>
</template>
<script setup lang="ts">
import type { MappedAggregation } from '@robustastudio/e-commerce/common';
import { isEqual, uniqWith } from 'lodash-es';
import type { ConcreteComponent } from 'vue';

function arrayToAggregationOption(array: number[]): string {
  return `${array[0]}-${array[1]}`;
}
const facetTypeComponentMapper: Record<string, ConcreteComponent | string> = {
  price: resolveComponent('FacetTagPrice'),
  select: resolveComponent('FacetTagSelect'),
};

/**
 * @description responsible for wrapping the facets object and render the selected facets based on the values selected
 */
const props = defineProps({
  facets: {
    type: Array as PropType<MappedAggregation[]>,
    required: false,
    default: () => [],
  },
});

const emit = defineEmits(['click']);

const selectedFacets = computed(() => {
  return props.facets.reduce<Array<MappedAggregation>>((allSelectedFacets, facet) => {
    const newValues = uniqWith(
      toNonNullable(Array.isArray(facet.value) ? facet.value : [facet.value]).map(v => ({
        ...v,
        type: facet.type,
        code: facet.code,
        label: facet.type === 'price' ? 'Price' : v.label,
        value: facet.type === 'price' ? resolvePriceValue(facet) : v.value,
      })),
      function (a, b) {
        return a.code === b.code && a.value === b.value;
      },
    ).filter(v => !!v.value);

    return [...allSelectedFacets, ...(newValues ?? [])] as Array<MappedAggregation>;
  }, []);
});

function resolvePriceValue(facet: MappedAggregation) {
  if (isEqual(facet.value, facet.options)) return '';
  return arrayToAggregationOption(facet.value as unknown as number[]);
}

function handleOnSelectItem(e: MappedAggregation) {
  if (e.type === 'price') {
    emit('click', e);
    return;
  }

  emit('click', e);
}
</script>
