<template>
  <Teleport to="#after-header">
    <transition name="fade">
      <div v-if="isOpen" class="bg-black fixed inset-0 w-full h-full opacity-50 z-30 lg:hidden" @click="close"></div>
    </transition>

    <transition name="slide-up">
      <div v-if="isOpen" class="z-40 fixed inset-0 w-full h-full flex flex-col bg-white lg:hidden">
        <div class="py-8 h-full overflow-y-auto relative">
          <button
            class="text-sm [ flex items-center justify-center ] [ w-8 h-8 ] [ absolute top-7 right-4 ] z-50"
            @click="close"
          >
            <svg-icon-close class="text-primary-1-100" width="28" height="28" />
          </button>

          <header class="w-full text-primary-1-100 text-lg relative [ pb-5 px-5 z-0 ] [ flex items-center ]">
            <h3 class="font-bold text-lg mr-3">{{ $t('filterBy') }}</h3>
            <button
              class="underline uppercase text-primary-1-100 font-semibold text-sm"
              :aria-label="$t('clear')"
              @click="resetFilters"
            >
              {{ $t('clear') }}
            </button>
          </header>
          <template v-if="withSorting">
            <section class="px-6 border-b-4 border-primary-1-05">
              <h3 class="text-primary-1-100 font-bold text-md">{{ $t('productSorting') }}</h3>
              <section class="p-0 mt-5 flex flex-wrap gap-4 pb-7">
                <button
                  v-for="(option, idx) in options"
                  :key="idx"
                  class="h-10 px-4 border border-primary-1-100 flex items-center justify-center capitalize"
                  :class="{
                    'bg-white text-black': selectedOption?.label !== option.label,
                    'bg-primary-1-100 text-white': selectedOption?.label === option.label,
                  }"
                  @click="selectedOption = option"
                >
                  {{ $t(option.label) }}
                </button>
              </section>
            </section>
          </template>

          <div v-for="(facet, idx) in facetsInput.filter(facet => facet.type !== 'hidden')" :key="facet.code">
            <div
              v-if="facet.type === 'toggle'"
              class="px-5 flex items-center justify-between h-full w-full border-b-4 border-primary-1-05 px-6 py-8"
            >
              <span class="text-primary-1-100 text-md font-bold">{{ facet.label }}</span>
              <ToggleInput
                :model-value="Array.isArray(facet.value) && facet.value.length ? facet.value[0] : []"
                :true-value="
                  (facet.options as AggregationOption[])?.find(option => (option.value as unknown as string) === '1')
                "
                :false-value="[]"
                @input="($event: AggregationOption) => updateFacet($event, facet)"
              />
            </div>

            <AppAccordion
              v-if="['price', 'select'].includes(facet.type)"
              v-model="selectedSortOption"
              :name="facet.code"
              class="p-6"
              :class="{ 'border-b-4 border-primary-1-05': idx !== facetsInput.length - 1 }"
            >
              <template #summary>
                <h3 class="text-primary-1-100 text-md font-bold">
                  {{ facet.type === 'price' ? $t('price') : facet.label }}
                </h3>
              </template>

              <div v-if="facet.type !== 'price'" class="mt-4 space-y-5">
                <div
                  v-for="(option, index) in facet.options"
                  :key="index"
                  class="filter-item flex items-center justify-between"
                >
                  <span class="text-primary-1-100">{{ (option as any)?.label || '' }}</span>

                  <CheckboxInput
                    :name="facet.code"
                    :value="facet.value"
                    :checked-value="option"
                    variant="secondary"
                    @input="$event => $event && updateFacet($event, facet)"
                  />
                </div>
              </div>
              <PriceFacet
                v-if="facet.type === 'price'"
                :value="
                  (facet.value as AggregationOption[])?.map(f => Number(f.value ?? 0)) as unknown as [number, number]
                "
                :options="facet.options as [number, number]"
                @input="$event => $event && updateFacet($event, facet)"
              />
            </AppAccordion>
          </div>
          <div class="mt-10 px-5 lg:px-0">
            <AppButton
              class="bg-primary-800 py-3 w-full px-16 text-white justify-center"
              :aria-label="$t('applyFilters')"
              inverted
              @click="handleApplyFilters"
            >
              <span class="uppercase font-normal">
                {{ $t('applyFilters') }}
              </span>
            </AppButton>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script setup lang="ts">
import type { AggregationOption, MappedAggregation } from '@robustastudio/e-commerce/common';
import { cloneDeep } from 'lodash-es';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  value: {
    type: Array as PropType<MappedAggregation[]>,
    required: true,
  },
  withSorting: {
    type: Boolean,
    default: false,
  },
});

const { t: $t } = useI18n({
  useScope: 'local',
});

const { options, selectedOption } = useSortBy();

const emit = defineEmits<{
  (e: 'apply', value: MappedAggregation[]): void;
  (e: 'update:isOpen', value: boolean): void;
  (e: 'sort', value: typeof selectedOption.value): void;
}>();

/*
 * On Dialog close event handler.
 * @returns {void}
 * emit close event , and emits facets value directly if immediate is true
 */
function close() {
  emit('update:isOpen', false);
}

const selectedSortOption = ref('');

/*
 * @summary: facets internal input state object
 * @description: hold a clone of the passed value of facets object
 * @updated on facets props get changed
 * @type {MappedAggregation[]}
 */
const facetsInput = ref(cloneDeep(props.value));
/*
 * prevent window from being scrolled when dialog is open
 */
watchEffect(() => {
  if (process.server) {
    return;
  }

  window.document.body.classList.toggle('overflow-hidden', props.isOpen);
});

/*
 * Handle reset filter/facets options values to default
 */
function resetFilters() {
  facetsInput.value.forEach((facet: any) => {
    if (facet.type === 'price') {
      facet.value = [...facet.options];
      return;
    }

    facet.value = [];
  });

  selectedOption.value = options.value?.at(0);

  handleApplyFilters();
}

/*
 * Handle apply filters button event
 */
function handleApplyFilters() {
  emit('apply', facetsInput.value);

  emit('sort', selectedOption.value);

  close();
}

/*
 * Handle when facet value is updated by user , update the selected facet value with the selected option.
 */
function updateFacet(selectedValues: Array<AggregationOption> | AggregationOption, facet: MappedAggregation) {
  if (Array.isArray(selectedValues)) {
    facet.value = selectedValues;
    return;
  }
  facet.value = [selectedValues];
}

/*
 * update selected facet values when facets props gets updated
 */
watch(
  () => props.value,
  value => (facetsInput.value = cloneDeep(value)),
);
</script>

<style lang="postcss" scoped>
.filter-item:first-child {
  @apply mt-0;
}

.filter-item:last-child {
  @apply pb-0;
}

.filter-item {
  @apply pb-4 mt-4;
}

.filter-item:not(:last-child) {
  @apply border-b border-gray-100;
}
</style>

<i18n>
{
  "en": {
    "filterBy": "Filter by",
    "applyFilters": "Apply Filters",
    "clear": "RESET ALL",
    "productSorting": "Sort By",
    "name": "Name",
    "priceHighToLow": "Price (High to Low)",
    "priceLowToHigh": "Price (Low to High)",
    "ratingHighToLow": "Rating (High to Low)",
    "ratingLowToHigh": "Rating (Low to High)",
    "close":"Close",
    "price": "Price"
  },
  "ar": {
    "filterBy": "تصفية المنتجات",
    "applyFilters": "تصفيه",
    "clear": "اعاده تصفيه",
    "productSorting": "ترتيب المنتجات",
    "name": "الاسم",
    "priceHighToLow": "السعر (من الأكبر  الى الاقل)",
    "priceLowToHigh": "السعر (من الاقل الى الأكبر)",
    "ratingHighToLow": "التقييم (من الأكبر  الى الأقل)",
    "ratingLowToHigh": "التقييم (من الأقل الى الأكبر)",
    "close":"اغلاق",
    "price": "السعر"
  }
}
</i18n>
