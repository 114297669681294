<template>
  <form class="GenericMenu w-full bg-white border border-primary-1-15 z-20">
    <div
      v-for="(option, index) in optionsToDisplay"
      :key="option.label || index"
      class="bg-white text-sm text-primary-1-100 mb-3"
      @click="onSelect(option)"
    >
      <input
        :type="single ? 'radio' : 'checkbox'"
        aria-label=""
        :name="`checkbox-${option.label}`"
        :class="{ single: single }"
      />
      <label
        :for="`checkbox-${option.label}`"
        class="flex items-center relative cursor-pointer"
        :class="{
          'is-checked': Array.isArray(isCheckedValues)
            ? isCheckedValues.includes(option?.value)
            : isCheckedValues === option?.value,
        }"
      >
        {{ hasTranslation ? $t(option.label || '') : option.label }}
      </label>
    </div>
    <transition name="slide">
      <template v-if="options.length > 6 && !showMoreOptions">
        <button
          type="button"
          class="underline font-medium text-sm text-primary-1-100 mt-5 cursor-pointer"
          :aria-label="$t('showMore')"
          @click="toggleShowMore"
        >
          {{ $t('showMore', { filter: label }) }}
        </button>
      </template>
    </transition>

    <transition name="slide">
      <template v-if="showMoreOptions">
        <div>
          <div
            v-for="option in moreOptions"
            :key="`${option.label}-${option.value}`"
            class="text-primary-1-100 mb-3"
            @click="onSelect(option)"
          >
            <input :type="single ? 'radio' : 'checkbox'" aria-label="" :name="`checkbox-${option.label}`" />
            <label
              :for="`checkbox-${option.label}`"
              class="flex items-center relative cursor-pointer"
              :class="{
                'is-checked': Array.isArray(isCheckedValues)
                  ? isCheckedValues.includes(option?.value)
                  : isCheckedValues === option?.value,
              }"
            >
              {{ hasTranslation ? $t(option.label || '') : option.label }}
            </label>
          </div>
        </div>
      </template>
    </transition>
  </form>
</template>

<script setup lang="ts">
import { clone } from 'lodash-es';

type Option = {
  label?: string | null;
  value: any; // TODO convert this one to generic type
};

const props = defineProps({
  modelValue: {
    type: null,
    default: () => [],
  },
  options: {
    type: Array as PropType<Array<Option>>,
    required: true,
  },
  single: {
    type: Boolean,
    default: false,
  },
  immediate: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  hasTranslation: {
    type: Boolean,
    default: false,
  },
  identifiedBy: {
    // used to identify the option identifier in the model
    type: String,
    default: 'id',
  },
});

const emit = defineEmits(['input', 'close']);

const { t: $t } = useI18n({
  useScope: 'local',
});

const selectedOptions = ref<any[] | any>(toRaw(props.modelValue));

if (props.single)
  watch(
    () => props.modelValue,
    newValue => {
      selectedOptions.value = clone(newValue);
    },
  );

/** controls showing more than 6 options */
const showMoreOptions = ref<boolean>(false);

/** options to be displayed initially (6 maximum) */
const optionsToDisplay = computed(() => {
  if (props.options.length > 6) {
    return props.options.slice(0, 6);
  }
  return props.options;
});

/** options to be displayed after toggling show more (if any) */
const moreOptions = computed(() => {
  if (props.options.length > 6) {
    return props.options.slice(6);
  }
  return [];
});

/**
 * a function that accept array of primitive type or array f object type
 * and return the value to be matched with in case of primitive type
 * and return the value of the identifiedBy property in case of object type
 */
function getMatchedItem(options: any[], value: any): number {
  if (options.length && typeof options[0] === 'object' && options[0] !== null) {
    return options.findIndex(option => option[props.identifiedBy] === value[props.identifiedBy]) ?? -1;
  }
  return options.indexOf(options[0]);
}

/**
 * called on selecting one of the options
 * handles emitting the selected value throught an input event
 */
function onSelect(value: Option) {
  if (props.single) {
    emit('input', value);
    return;
  }

  const newValue = clone(props.modelValue);
  const idx = getMatchedItem(newValue, value);
  if (idx >= 0) {
    newValue.splice(idx, 1);
  } else {
    newValue.push(value);
  }

  selectedOptions.value = newValue;
}

if (props.immediate) {
  watch(selectedOptions, newValue => {
    emit('input', newValue);
    emit('close');
  });
}

/**
 * Checks if an option is checked based on the model value
 */
const isCheckedValues = computed(() => {
  return Array.isArray(selectedOptions.value)
    ? selectedOptions.value.map((opt: any) => toRaw(opt.value))
    : toRaw(selectedOptions.value?.value);
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function apply() {
  emit('input', selectedOptions.value);
  emit('close');
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function cancel() {
  selectedOptions.value = clone(props.modelValue);
  emit('close');
}

/**
 * Toggles the show more options if we have more than 6 options
 */
function toggleShowMore() {
  showMoreOptions.value = true;
}
</script>

<style lang="postcss" scoped>
.GenericMenu {
  input[type='checkbox'] {
    appearance: none;
    margin: 0;
    & + label::before {
      content: '';
      @apply bg-transparent w-6 h-6 border border-primary-1-100 mr-4 inline-block cursor-pointer;
    }

    & + label.is-checked::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1.5rem;
      height: 1.5rem;
      background: url('~/assets/icons/check.svg') no-repeat center;
    }
  }

  input[type='radio'] {
    appearance: none;
    margin: 0;
    & + label::before {
      content: '';
      @apply bg-transparent w-6 h-6 border border-primary-1-100 rounded-full mr-4 inline-block cursor-pointer;
    }

    & + label.is-checked::after {
      content: '';
      position: absolute;
      top: 0.25rem;
      left: 0.25rem;
      width: 1rem;
      height: 1rem;
      box-shadow: none;
      background: black;
      border-radius: 50%;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "cancel": "Cancel",
    "apply": "Apply",
    "showMore": "Show More {filter}",
    "sortBy": "Sort By",
    "name": "Name",
    "ratingHighToLow": "Rating (High to Low)",
    "ratingLowToHigh": "Rating (Low to High)",
    "priceLowToHigh": "By Price From Low To High",
    "priceHighToLow": "By Price From High To Low"
  },
  "ar": {
    "cancel": "اغلاق",
    "apply": "اختيار",
    "showMore": "عرض المزيد من {filter}",
    "sortBy": "ترتيب",
    "name": "الاسم",
    "ratingHighToLow": "التقييم (من الأعلى إلى الأقل)",
    "ratingLowToHigh": "التقييم (من الأقل إلى الأعل)",
    "priceLowToHigh": "حسب السعر من الاقل للأكبر",
    "priceHighToLow": "حسب السعر من الاكبر للأقل"
  }
}
</i18n>
