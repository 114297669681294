<template>
  <div class="AppAccordion" :class="{ 'is-active': isExpanded }">
    <div class="Summary flex items-center justify-between cursor-pointer" @click="toggle(false)">
      <slot name="summary">
        <span>{{ label }}</span>
      </slot>

      <button type="button" class="h-10 flex items-center justify-center">
        <svg-icon-chevron-down
          class="transform w-4 h-4"
          :class="{
            'rotate-180': isExpanded,
          }"
        />
      </button>
    </div>

    <div>
      <slot v-if="isExpanded" />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  modelValue: {
    type: null,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const isExpanded = computed(() => {
  if (props.name) {
    return props.modelValue === props.name;
  }

  return props.modelValue === true;
});

function toggle(force?: boolean) {
  if (typeof force === 'boolean') {
    emit('update:modelValue', props.name || true);
    return;
  }

  emit('update:modelValue', isExpanded.value ? null : props.name || true);
}
</script>

<style lang="postcss" scoped>
.AppAccordion {
  @apply flex flex-col;

  &__title {
    @apply flex items-center;
  }
}
</style>
